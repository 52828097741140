
#id65fed533b0184704f9097bb8 {
  #i3cg {
min-height : 100px; 
font-family : Helvetica, sans-serif; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7fe 0%, #f2f7fe 100%); 
} 
#i2ow {
padding : 80px 2% 80px 2%; 
min-height : 100px; 
} 
#i9n2 {
padding : 80px 2% 80px 2%; 
min-height : 100px; 
} 
#izg5 {
padding : 80px 2% 80px 2%; 
min-height : 100px; 
} 
#ipvc3 {
padding : 10px 5% 10px 5%; 
min-height : 100px; 
} 
#iat5x {
padding : 90px 5% 20px 5%; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
color : #000000; 
display : flex; 
justify-content : space-between; 
flex-wrap : wrap; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.botonprincipal {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#50c10a 0%, #50c10a 100%); 
color : #ffffff; 
text-decoration : none; 
padding : 10px 30px 10px 30px; 
border-radius : 25px 25px 25px 25px; 
} 
.botonprincipal:hover  {
color : #ffffff; 
text-decoration : none; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#439b0c 0%, #439b0c 100%); 
} 
.botonsecundario {
padding : 10px 30px 10px 30px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#fdac2b 0%, #fdac2b 100%); 
color : #ffffff; 
text-decoration : none; 
border-radius : 25px 25px 25px 25px; 
} 
.botonsecundario:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#d48d1c 0%, #d48d1c 100%); 
color : #ffffff; 
text-decoration : none; 
} 
#ifuv8 {
padding : 10px 0px 10px 0px; 
min-height : 100px; 
color : #3e4654; 
} 
#ikaxo {
padding : 10px 0px 10px 0px; 
display : flex; 
justify-content : space-around; 
flex-wrap : wrap; 
margin : 30px 0 0 0; 
} 
#i4o27 {
min-height : 100px; 
} 
.tajerta {
width : 245px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#fbfcff 0%, #fbfcff 100%); 
box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.12) ; 
margin : 10px 10px 10px 10px; 
padding : 50px 25px 50px 25px; 
border-radius : 30px 30px 30px 30px; 
} 
.tajerta:hover  {
box-shadow : 0 0 5px 0 rgba(0,0,0,0.58) ; 
} 
#idd4g {
padding : 10px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#ivwi3 {
padding : 10px; 
display : block; 
font-weight : 700; 
text-align : center; 
font-size : 1.1rem; 
} 
#iu1pt {
padding : 20px 20px 20px 20px; 
border-radius : 50% 50% 50% 50%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7fe 0%, #f2f7fe 100%); 
} 
#iw65h {
color : black; 
min-height : 10px; 
} 
#iwrff {
color : black; 
} 
#ie9oo {
padding : 20px 20px 20px 20px; 
border-radius : 50% 50% 50% 50%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7fe 0%, #f2f7fe 100%); 
} 
#ijd9q {
padding : 10px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#ije0t {
padding : 10px; 
display : block; 
font-weight : 700; 
text-align : center; 
font-size : 1.1rem; 
} 
#i3zg7 {
min-height : 100px; 
} 
#isluk {
color : black; 
} 
#i1zej {
padding : 20px 20px 20px 20px; 
border-radius : 50% 50% 50% 50%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7fe 0%, #f2f7fe 100%); 
} 
#ix6um3 {
padding : 10px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#iv5rgk {
padding : 10px; 
display : block; 
font-weight : 700; 
text-align : center; 
font-size : 1.1rem; 
} 
#ijp4bj {
min-height : 100px; 
} 
#ipuxfl {
padding : 10px; 
min-height : 100px; 
display : flex; 
color : #3e4654; 
flex-wrap : wrap; 
} 
#izeymg {
padding : 10px; 
min-height : 100px; 
width : 50%; 
display : flex; 
align-items : center; 
flex-direction : column; 
justify-content : space-between; 
} 
.item {
height : 200px; 
margin-top : 50px; 
margin-right : auto; 
margin-bottom : 50px; 
margin-left : auto; 
padding-top : 75px; 
text-align : center; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#ijcy9b {
padding : 10px; 
min-height : 100px; 
} 
#i0jvoi {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-around; 
margin : 0px 0px 0px 0px; 
} 
#ieuh5w {
padding : 10px; 
min-height : 100px; 
color : #3e4654; 
} 
#inqqhh {
padding : 10px; 
min-height : 100px; 
} 
#if2zj9 {
min-height : 100px; 
padding : 10px; 
} 
#ise2r9 {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-around; 
} 
#i0qriz {
padding : 10px; 
min-height : 100px; 
} 
#i14ubl {
padding : 10px; 
display : block; 
font-weight : 600; 
} 
#icpwci {
padding : 10px; 
display : block; 
} 
#i8hlod {
padding : 10px; 
min-height : 100px; 
font-size : 14px; 
} 
#i0vnkg {
padding : 10px; 
display : block; 
} 
#i7jvdl {
padding : 10px; 
display : block; 
font-weight : 600; 
} 
#ic9ies {
padding : 10px; 
display : block; 
font-weight : 600; 
} 
#icjl6l {
padding : 10px; 
display : block; 
} 
#io7ssq {
padding : 10px; 
display : block; 
} 
#idmvel {
padding : 10px; 
min-height : 100px; 
font-size : 14px; 
} 
#ieb919 {
padding : 10px; 
min-height : 100px; 
} 
#i5kwyk {
padding : 10px; 
display : block; 
font-weight : 600; 
} 
#izt1ml {
padding : 10px; 
display : block; 
} 
#ip0pg2 {
padding : 10px; 
display : block; 
} 
#ijr81i {
padding : 10px; 
min-height : 100px; 
font-size : 14px; 
} 
#ia2by2 {
padding : 10px; 
min-height : 100px; 
} 
#ipa2qp {
padding : 10px; 
min-height : 100px; 
border : 1px solid #f37604; 
border-radius : 30px 30px 30px 30px; 
width : 695.0574999999999px; 
} 
#ixz1b7 {
padding : 10px; 
text-align : right; 
width : 100%; 
} 
#i4aa3m {
padding : 10px; 
display : block; 
} 
#iiv8pp {
padding : 10px; 
display : block; 
} 
#i4plpg {
padding : 10px; 
display : block; 
} 
#il33xc {
min-height : 10px; 
} 
#iwoswk {
min-width : 50px; 
min-height : 30px; 
cursor : pointer; 
} 
#i9zfqe {
padding : 10px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#ilo28e {
display : flex; 
} 
#igv37w {
display : flex; 
} 
#i7xa9p {
display : flex; 
} 
#i43u4t {
color : black; 
} 
#iikhi2 {
padding : 20px 20px 20px 20px; 
border-radius : 50% 50% 50% 50%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7fe 0%, #f2f7fe 100%); 
} 
#i1n0f9 {
padding : 10px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#id21fx {
padding : 10px; 
display : block; 
font-weight : 700; 
text-align : center; 
font-size : 1.1rem; 
} 
#i6xebl {
min-height : 100px; 
} 
#ig2s32 {
display : flex; 
} 
#ih1kn7 {
color : black; 
} 
#idnsai {
padding : 20px 20px 20px 20px; 
border-radius : 50% 50% 50% 50%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7fe 0%, #f2f7fe 100%); 
} 
#ika8s7 {
padding : 10px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#iuo0iw {
padding : 10px; 
display : block; 
font-weight : 700; 
text-align : center; 
font-size : 1.1rem; 
} 
#i9rgcm {
min-height : 100px; 
} 
#ihzpk7 {
display : flex; 
} 
#ipfeog {
min-height : 100px; 
} 
.tarjeta {
width : 380px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 30px 30px 30px 30px; 
padding : 60px 10px 60px 10px; 
} 
#itgpjo {
min-height : 100px; 
} 
#i3ho6u {
min-height : 100px; 
} 
.linea-tarjeta {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#d8e0eb 0%, #d8e0eb 100%); 
width : 2px; 
} 
#it0t47 {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#i1lbie {
color : black; 
width : 75%; 
border-radius : 30px 30px 30px 30px; 
} 
#i4q4uj {
color : black; 
width : 75%; 
border-radius : 30px 30px 30px 30px; 
} 
#iuv1r7 {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#igpqot {
color : black; 
width : 75%; 
border-radius : 30px 30px 30px 30px; 
} 
#i3a3eo {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#izejpj {
padding : 10px; 
min-height : 100px; 
} 
#i040xk {
padding : 10px; 
display : block; 
font-size : 25px; 
text-align : center; 
font-weight : 600; 
} 
#ikq2x5 {
padding : 10px; 
display : block; 
text-align : center; 
} 
#iz2b8p {
display : block; 
padding : 10px 10px 10px 10px; 
} 
#iyw3al {
padding : 10px; 
display : block; 
text-align : center; 
} 
#ixq5ql {
padding : 10px; 
min-height : 100px; 
} 
#idd801 {
padding : 10px; 
display : block; 
font-size : 25px; 
text-align : center; 
font-weight : 600; 
} 
#imr5qh {
padding : 10px; 
display : block; 
text-align : center; 
} 
#ihnf3g {
padding : 10px; 
min-height : 100px; 
} 
#iw4tpx {
padding : 10px; 
min-height : 20vh; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
align-items : center; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
#iz999t {
padding : 10px; 
min-height : 80vh; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : left bottom; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk5ilxw3915k.jpg'); 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#i7xb8r {
min-height : 100px; 
display : block; 
} 
#iw2km3 {
color : black; 
width : 180px; 
} 
#i282zh {
padding : 10px; 
min-height : 100px; 
width : 480px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : space-around; 
} 
#iucead {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : flex-end; 
align-items : center; 
} 
#igkctr {
padding : 5px; 
width : auto; 
height : auto; 
display : block; 
} 
#im43t5 {
width : auto; 
min-height : 10px; 
padding : 5px 5px 5px 5px; 
} 
.link-nabvar {
text-decoration : none; 
color : #024c99; 
font-size : 20px; 
} 
.link-nabvar:hover  {
color : #959595; 
} 
#id5l3e {
width : auto; 
min-height : 10px; 
padding : 5px 5px 5px 5px; 
} 
#iugyy5 {
padding : 5px; 
width : auto; 
height : auto; 
display : block; 
} 
#io4byk {
width : auto; 
min-height : 10px; 
padding : 5px 5px 5px 5px; 
} 
#iibluu {
padding : 5px; 
width : auto; 
height : auto; 
display : block; 
} 
#i4h4c3 {
width : auto; 
min-height : 10px; 
padding : 5px 5px 5px 5px; 
} 
#iajvrv {
padding : 5px; 
width : auto; 
height : auto; 
display : block; 
} 
#inoz1l {
display : inline-block; 
min-width : 50px; 
cursor : pointer; 
text-align : center; 
} 
#imdyke {
display : block; 
padding : 10px 10px 10px 10px; 
margin : 0px 15px 0px 15px; 
} 
#idnkr7 {
color : black; 
} 
#ixrczl {
color : black; 
} 
#io94k5 {
display : block; 
margin : 0px 15px 0px 15px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(2,76,153,0.29) 0%, rgba(2,76,153,0.29) 100%); 
padding : 10px 10px 10px 10px; 
} 
.boton-lineal-azul {
border-radius : 28px 28px 28px 28px; 
border : 1px solid #024c99; 
text-decoration : none; 
color : #024c99; 
width : 175px; 
align-items : center; 
font-size : 20px; 
padding : 14px 20px 14px 20px; 
} 
.boton-lineal-azul:hover  {
color : white; 
text-decoration : none; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#024c99 0%, #024c99 100%); 
} 
#i70b3l {
padding : 100px 70px 100px 70px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(252,252,252,0.9) 0%, rgba(252,252,252,0.9) 100%); 
border-radius : 30px 30px 30px 30px; 
} 
#ics73h {
padding : 10px; 
display : block; 
text-align : center; 
} 
#i2pigk {
padding : 10px; 
display : block; 
text-align : center; 
font-size : 30px; 
color : #024c99; 
} 
#i92aaq {
padding : 10px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#i40vca {
display : inline-block; 
min-width : 50px; 
cursor : pointer; 
} 
.boton-lineal-naranja {
border : 1px solid #f37900; 
border-radius : 28px 28px 28px 28px; 
color : #f37900; 
text-decoration : none; 
width : 200px; 
text-align : center; 
padding : 15px 20px 15px 20px; 
} 
.boton-lineal-naranja:hover  {
color : white; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f37900 0%, #f37900 100%); 
} 
.title-imacem {
font-size : 45px; 
font-weight : 800; 
color : #024c99; 
} 
#i3pnpq {
min-height : 100px; 
padding : 10px; 
} 
#i9fgka {
min-height : 100px; 
} 
.titulo-seccion {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ebebeb 0%, #ebebeb 100%); 
border-radius : 30px 30px 30px 30px; 
display : flex; 
justify-content : space-between; 
align-items : center; 
padding : 10px 20px 10px 20px; 
} 
#ib4o8l {
padding : 10px; 
display : block; 
} 
#in8574 {
padding : 10px; 
display : block; 
} 
.titulo-seccion-imacem {
font-size : 45px; 
font-weight : 700; 
color : #024c99; 
} 
.subtitulo-seccion-imacem {
font-size : 28px; 
color : #024c99; 
font-weight : 300; 
} 
#i2l4sd {
padding : 10px; 
display : block; 
text-align : left; 
font-size : 50px; 
font-weight : 700; 
} 
#ic97nk {
padding : 10px; 
display : block; 
margin : 0 0 30px 0; 
text-align : left; 
color : #000000; 
font-weight : 300; 
font-size : 20px; 
} 
#it0inh {
display : block; 
} 
#izpc7n {
display : block; 
} 
#irxvll {
padding : 10px; 
display : flex; 
} 
#idwzm3 {
padding : 70px 50px 70px 50px; 
min-height : 100px; 
width : 100%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 30px 30px 30px 30px; 
display : flex; 
flex-direction : column; 
align-items : stretch; 
justify-content : center; 
} 
#imwp6h {
padding : 10px; 
min-height : 100px; 
width : 50%; 
display : flex; 
align-items : stretch; 
} 
#iyhhw3 {
min-height : 100px; 
padding : 10px; 
width : 75%; 
} 
#ijylrh {
color : black; 
width : 100%; 
border-radius : 30px 30px 30px 30px; 
} 
#ip5g8s {
color : black; 
width : 100%; 
border-radius : 30px 30px 30px 30px; 
} 
#iu127m {
min-height : 100px; 
padding : 10px; 
width : 75%; 
} 
.boton-lineal-azul.large-button {
width : 280px; 
text-align : center; 
} 
#i65amn {
min-height : 100px; 
} 
.titulo-seccion-secundario {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ebebeb 0%, #ebebeb 100%); 
border-radius : 30px 30px 30px 30px; 
display : flex; 
justify-content : space-between; 
align-items : center; 
padding : 15px 20px 15px 20px; 
margin : 20px 0px 20px 0px; 
} 
#ihgxib {
padding : 10px; 
display : block; 
} 
.titulo-seccion-secudario {
color : #024c99; 
font-size : 50px; 
font-weight : 700; 
} 
.titulo-tarjeta {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#eeeeee 0%, #eeeeee 100%); 
border-radius : 5px 5px 5px 5px; 
font-size : 25px; 
text-align : center; 
font-weight : 600; 
} 
.descripcion-tarjeta {
color : #959595; 
margin : 25px 0px 0px 0px; 
} 
#itghdq {
padding : 10px; 
display : block; 
} 
#inibo6 {
min-height : 100px; 
} 
#ib6e0r {
padding : 10px; 
display : block; 
} 
.descripcion-seccion-2 {
color : #024c99; 
font-size : 35px; 
font-weight : 300; 
} 
#iffnpy {
padding : 10px; 
display : block; 
} 
#i8bur9 {
padding : 10px; 
display : block; 
} 
#imdp4j {
min-height : 100px; 
} 
.tarjeta-blog {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
border-radius : 30px 30px 30px 30px; 
width : 350px; 
} 
#iq46jc {
padding : 10px; 
min-height : 100px; 
margin : 20px 0 0 0; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f5f3f3 0%, #f5f3f3 100%); 
border-radius : 30px 30px 30px 30px; 
color : black; 
text-align : center; 
} 
#ighxck {
padding : 10px; 
min-height : 100px; 
} 
#inqivl {
color : black; 
width : 400px; 
} 
#ifnuqh {
display : none; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : none; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
#iu51kr {
padding : 10px; 
display : block; 
text-align : center; 
} 
.title-imacem.subtitle-imacem {
font-size : 35px; 
margin : 15px 0 0 0; 
} 
.vinculo-negro {
color : black; 
text-decoration : none; 
} 
#imdyke:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(2,76,153,0.29) 0%, rgba(2,76,153,0.29) 100%); 
} 
.img-servicio {
width : 200px; 
border-radius : 50% 50% 50% 50%; 
} 
#io94k5:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(2,76,153,0.29) 0%, rgba(2,76,153,0.29) 100%); 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#ibku4t {
min-height : 100px; 
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
} 
#id8rut {
padding : 10px; 
} 
#ikv47j {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
font-size : 1.5rem; 
font-weight : 700; 
} 
#ifjn9i {
width : 100%; 
min-height : 100px; 
display : block; 
} 
#i4179f {
min-height : 100px; 
height : 220px; 
border-radius : 25px 25px 25px 25px; 
overflow : hidden; 
} 
.imgDynamics {
width : 100px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#i7uavv {
padding : 5px; 
width : 100%; 
height : auto; 
border-radius : 25px 25px 25px 25px; 
} 
#imrhdg {
color : black; 
} 
#iqyq65 {
color : black; 
} 
#ipls1l {
color : black; 
} 

  @media only screen and (max-width: 992px) {.tajerta {
margin : 10px 10px 10px 10px; 
width : 250px; 
}} 
@media only screen and (max-width: 992px) {#i9n2 {
padding : 80px 10px 80px 10px; 
}} 
@media only screen and (max-width: 992px) {#ipuxfl {
flex-direction : column-reverse; 
}} 
@media only screen and (max-width: 992px) {#izeymg {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#izg5 {
padding : 80px 10px 80px 10px; 
}} 
@media only screen and (max-width: 992px) {#i2ow {
padding : 80px 10px 80px 10px; 
}} 
@media only screen and (max-width: 992px) {#ipvc3 {
padding : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 992px) {#iat5x {
padding : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 992px) {#i0qriz {
width : 239.09088134765625px; 
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 992px) {#ia2by2 {
width : 161.81817626953125px; 
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 992px) {#ieb919 {
width : 171.8182373046875px; 
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 992px) {.tarjeta {
width : 220px; 
margin : 5px 5px 5px 5px; 
}} 
@media only screen and (max-width: 992px) {#ixq5ql {
padding : 0px 0px 0px 0px; 
}} 
@media only screen and (max-width: 992px) {#ihnf3g {
padding : 0px 0px 0px 0px; 
}} 
@media only screen and (max-width: 992px) {#izejpj {
padding : 0px 0px 0px 0px; 
}} 
@media only screen and (max-width: 992px) {#imwp6h {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#iw4tpx {
flex-wrap : nowrap; 
min-height : 10vh; 
}} 
@media only screen and (max-width: 992px) {#i282zh {
width : 382px; 
}} 
@media only screen and (max-width: 992px) {.title-imacem {
font-size : 40px; 
}} 
@media only screen and (max-width: 992px) {#i2pigk {
font-size : 20px; 
}} 
@media only screen and (max-width: 992px) {.link-nabvar {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {.boton-lineal-azul {
font-size : 16px; 
width : 150px; 
}} 
@media only screen and (max-width: 992px) {#iw2km3 {
width : 100px; 
}} 
@media only screen and (max-width: 992px) {#i7xb8r {
display : flex; 
align-items : center; 
}} 
@media only screen and (max-width: 992px) {.titulo-seccion-imacem {
font-size : 30px; 
}} 
@media only screen and (max-width: 992px) {.subtitulo-seccion-imacem {
font-size : 20px; 
}} 
@media only screen and (max-width: 992px) {.tarjeta-blog {
width : 220px; 
margin : 5px 5px 5px 5px; 
}} 
@media only screen and (max-width: 992px) {#inqivl {
width : 113.5625px; 
}} 
@media only screen and (max-width: 992px) {#ipa2qp {
width : 604.21875px; 
}} 
@media only screen and (max-width: 992px) {#ighxck {
display : flex; 
align-items : center; 
}} 
@media only screen and (max-width: 992px) {#ise2r9 {
padding : 0px 0px 0px 0px; 
}} 
@media only screen and (max-width: 992px) {.titulo-tarjeta {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#idd801 {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#i040xk {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#ikaxo {
justify-content : space-around; 
}} 
@media only screen and (max-width: 992px) {#ifnuqh {
display : none; 
}} 

  @media only screen and (max-width: 480px) {.botonprincipal {
display : none; 
}} 
@media only screen and (max-width: 480px) {.tajerta {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i2ow {
padding : 80px 0px 80px 0px; 
}} 
@media only screen and (max-width: 480px) {#i9n2 {
padding : 80px 0px 80px 0px; 
}} 
@media only screen and (max-width: 480px) {#inqqhh {
width : auto; 
}} 
@media only screen and (max-width: 480px) {#ipvc3 {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#i0qriz {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iat5x {
padding : 10px 5px 10px 5px; 
justify-content : space-around; 
}} 
@media only screen and (max-width: 480px) {#ia2by2 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ieb919 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#igv37w {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {.linea-tarjeta {
display : none; 
}} 
@media only screen and (max-width: 480px) {.tarjeta {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i2l4sd {
font-size : 1.8rem; 
}} 
@media only screen and (max-width: 480px) {#i282zh {
display : none; 
}} 
@media only screen and (max-width: 480px) {#iucead {
display : none; 
}} 
@media only screen and (max-width: 480px) {#iw2km3 {
display : none; 
}} 
@media only screen and (max-width: 480px) {#ifnuqh {
color : black; 
width : 130px; 
display : block; 
}} 
@media only screen and (max-width: 480px) {#i70b3l {
width : 310px; 
padding : 100px 20px 100px 20px; 
}} 
@media only screen and (max-width: 480px) {#i3pnpq {
width : 310px; 
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {.title-imacem {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#i2pigk {
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {.boton-lineal-naranja {
font-size : 12px; 
}} 
@media only screen and (max-width: 480px) {#iz999t {
padding : 10px 0px 10px 0px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center bottom; 
background-attachment : scroll; 
background-size : auto; 
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk5ilwaouzi7.png'); 
}} 
@media only screen and (max-width: 480px) {.titulo-seccion {
flex-wrap : wrap; 
flex-direction : column; 
justify-content : space-between; 
align-items : stretch; 
}} 
@media only screen and (max-width: 480px) {.subtitulo-seccion-imacem {
text-align : right; 
}} 
@media only screen and (max-width: 480px) {#idwzm3 {
padding : 70px 5px 70px 5px; 
}} 
@media only screen and (max-width: 480px) {#ic97nk {
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {.boton-lineal-azul.large-button {
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#ijcy9b {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {.tarjeta-blog {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#inqivl {
width : 80%; 
}} 
@media only screen and (max-width: 480px) {#ighxck {
width : 100%; 
justify-content : center; 
}} 
@media only screen and (max-width: 480px) {#i188q6 {
padding : 10px; 
min-height : 100px; 
width : 65.80078125px; 
}} 
@media only screen and (max-width: 480px) {.contentwrapperanimated {
display : block; 
}} 

}
  